import { createReducer } from '@reduxjs/toolkit';
import { actions as types } from './actions';
import { actionRequest, actionSuccess } from '../helper';
import { Application, ApplicationCreation } from '../../services/application/models/Application';
import { GetPanDetailsResponse } from '../../services/application/models/GetPanDetailsResponse';
import { GetPincodeDetailsResponse } from '../../services/application/models/GetPincodeDetailsResponse';
import { ExtractedAddressProofDetails } from '../../services/application/models/ExtractedAddressProofDetails';
import { PendingActions } from '../../services/application/models/PendingActions';
import { GetBankDetailsByIFSCResponse } from '../../services/application/models/GetBankDetailsByIFSCResponse';
import { ExtractBankStatementDetailsResponse } from '../../services/application/models/ExtractBankStatementDetailsResponse';
import { UploadedBS } from './models/UploadedBS';
import { GetUploadedBankStatementsResponse } from '../../services/application/models/GetUploadedBankStatementsResponse';
import { UploadedIncomeProofs } from './models/UploadedIncomeProofs';
import { GetUploadedIncomeProofsResponse } from '../../services/application/models/GetUploadedIncomeProofsResponse';
import { UploadedMiscDocs } from './models/UploadedMiscDocs';
import { PanDetailsVerificationByNSDLResponse } from '../../services/application/models/PanDetailsVerificationByNSDLResponse';

export interface State
{
    quoteId?: string;
    creationReference?: string;
    applicationReference: number | null;
    applicationCreation: ApplicationCreation | null;
    pendingActions: PendingActions | null;
    panDetails: GetPanDetailsResponse | null;
    pincodeDetails: GetPincodeDetailsResponse | null;
    extractedAddress: ExtractedAddressProofDetails | null;
    uploadedBS: UploadedBS;
    ifscDetails: GetBankDetailsByIFSCResponse | null;
    extractedBs: ExtractBankStatementDetailsResponse | null,
    uploadedIncomeProofs: UploadedIncomeProofs;
    uploadedMiscDocs: UploadedMiscDocs;
    panDetailsVerificationResult: PanDetailsVerificationByNSDLResponse | null
}

const initialState: State = {
    quoteId: undefined,
    creationReference: undefined,
    applicationReference: null,
    applicationCreation: null,
    pendingActions: null,
    panDetails: null,
    pincodeDetails: null,
    extractedAddress: null,
    extractedBs: null,
    uploadedBS: {
        documents: undefined,
        firstApplicantDocument: undefined,
        firstCoApplicantDocument: undefined
    },
    ifscDetails: null,
    uploadedIncomeProofs: {
        documents: undefined
    },
    uploadedMiscDocs: {
        documents: undefined
    },
    panDetailsVerificationResult: null
};

export default createReducer(initialState, {

    [actionRequest(types.FETCH_APPLICATION_BY_QUOTE)]: (state) =>
    {
        state.quoteId = undefined;
        state.creationReference = undefined;
        state.applicationReference = null;
        state.applicationCreation = null;
        state.pendingActions = null;
    },
    [actionSuccess(types.FETCH_APPLICATION_BY_QUOTE)]: (state, action) =>
    {
        const payload: Application = action.payload;
        state.quoteId = payload.QuoteId;
        state.creationReference = payload.CreationReference;
        state.applicationReference = payload.ApplicationReference;
        state.applicationCreation = payload.ApplicationCreation ? {
            ...payload.ApplicationCreation,
            QuoteId: payload.QuoteId,
            ApplicationReference: payload.ApplicationReference,
            CreationReference: payload.CreationReference
        } : null;
        state.pendingActions = payload.PendingActions || null;
    },

    [actionRequest(types.FETCH_PAN_DETAILS)]: (state) =>
    {
        state.panDetails = null;
    },
    [actionSuccess(types.FETCH_PAN_DETAILS)]: (state, action) =>
    {
        state.panDetails = action.payload;
    },

    [actionRequest(types.FETCH_PINCODE_DETAILS)]: (state) =>
    {
        state.pincodeDetails = null;
    },
    [actionSuccess(types.FETCH_PINCODE_DETAILS)]: (state, action) =>
    {
        state.pincodeDetails = action.payload;
    },

    [actionRequest(types.EXTRACT_ADDRESS_PROOF_DETAILS)]: (state) =>
    {
        state.extractedAddress = null;
    },
    [actionSuccess(types.EXTRACT_ADDRESS_PROOF_DETAILS)]: (state, action) =>
    {
        state.extractedAddress = action.payload;
    },

    [actionSuccess(types.VERIFY_UPDATE_ADDRRESS_PROOF_DETAILS)]: (state, action) =>
    {
        state.extractedAddress = null;
    },

    [actionSuccess(types.FETCH_UPLOADED_BANK_STATEMENTS)]: (state, action) =>
    {
        const documents: GetUploadedBankStatementsResponse = action.payload;

        if (documents.Applicants.length > 0)
        {
            state.uploadedBS.firstApplicantDocument = documents.Applicants[0];
        }

        if (documents.CoApplicants.length > 0)
        {
            state.uploadedBS.firstCoApplicantDocument = documents.CoApplicants[0];
        }

        state.uploadedBS.documents = documents;
        state.uploadedBS.documents = documents;

    },

    [actionRequest(types.FETCH_IFSC_DETAILS)]: (state) =>
    {
        state.ifscDetails = null;
    },
    [actionSuccess(types.FETCH_IFSC_DETAILS)]: (state, action) =>
    {
        state.ifscDetails = action.payload;
    },

    [actionRequest(types.EXTRACT_BANK_STATEMENT_DETAILS)]: (state) =>
    {
        state.extractedBs = null;
    },
    [actionSuccess(types.EXTRACT_BANK_STATEMENT_DETAILS)]: (state, action) =>
    {
        state.extractedBs = action.payload;
    },
    [actionSuccess(types.FETCH_UPLOADED_INCOME_PROOFS)]: (state, action) =>
    {
        state.uploadedIncomeProofs.documents = action.payload;
    },
    [actionSuccess(types.FETCH_UPLOADED_MISC_DOCS)]: (state, action) =>
    {
        state.uploadedMiscDocs.documents = action.payload;
    },
    [actionSuccess(types.PAN_DETAILS_VERIFICATION_BY_NSDL)]: (state, action) =>
    {
        state.panDetailsVerificationResult = action.payload;
    },
});
